import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../../components/Layout/Layout"
import BlogRoll from "../../components/BlogRoll"
import useSiteMetadata from "../../components/SiteMetadata"


export const BlogIndex = ({data}) => {
  const {siteUrl} = useSiteMetadata()
  const description = (
    "Trusty Canary Blog: read about how you can detox your home by choosing the " +
    "right beds, furniture, paints, floors and more."
  )
  return (
    <Layout>
      <Helmet titleTemplate="%s | Trusty Canary">
        <title>Blog</title>
        <meta name="description" content={description}/>
        <meta property="og:description" content={description}/>
        <meta property="og:url" content={`${siteUrl}/blog/`}/>
      </Helmet>
      <BlogRoll posts={data.allMarkdownRemark.edges}/>
    </Layout>
  )
}


BlogIndex.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  count: PropTypes.integer
}

export default BlogIndex


export const pageQuery = graphql`
  query BlogRollQuery($date: Date) {
    allMarkdownRemark(
      sort: {
        order: DESC,
        fields: [frontmatter___date]
      }
      filter: {
        frontmatter: {
          templateKey: {eq: "blog-post"},
          date: {lte: $date},
          visible: {eq: true}
        }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            summary
            listingImage {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
