import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import PreviewCompatibleImage from "./PreviewCompatibleImage"

import Heading from "../components/Heading/Heading"
import Section from "../components/Section/Section"
import Segment from "../components/Segment/Segment"


class BlogRoll extends React.Component {
  render() {
    const { posts } = this.props
    return (
      <div>
        {posts &&
          posts.map(({ node: post }) => (
            <Section key={post.id} modifiers={["side-figure"]}>
              <Segment contentModifiers={["half-text"]}>
                <article>
                  <header>
                    <Heading level={2} modifiers={["major"]}>
                      <Link className="link link--title" to={post.fields.slug}>
                        {post.frontmatter.title}
                      </Link>
                    </Heading>
                    <Heading level={0} modifiers={["date"]}>
                      {post.frontmatter.date}
                    </Heading>
                  </header>
                  {post.frontmatter.summary.split("\n\n").map((para, i) => <p key={i}>{para}</p>)}
                  <Link className="link link--major" to={post.fields.slug}>
                    Read more
                  </Link>
                </article>
              </Segment>
              <Segment contentModifiers={["half-image"]}>
                <PreviewCompatibleImage image={post.frontmatter.listingImage} className="image-wrapper--full-height"/>
              </Segment>
            </Section>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  posts: PropTypes.array,
}

export default BlogRoll
